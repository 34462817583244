.navbar {
	width: 100vw;
	max-width: 2500px;
	height: var(--nav_height);
	background: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(10px);
	padding: 0.6875rem 1.4375rem;
	color: var(--text_color1);
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	top: 0;
	z-index: 10;
	// box-shadow: 0px 8px 8px 2px rgba(109, 141, 173, 0.15);

	.navOptions {
		display: flex;
		align-items: center;
		gap: 2.6rem;

		.navLinks {
			display: flex;
			align-items: center;
			gap: 1.2rem;

			@media screen and (min-width: 1400px) {
				gap: 1.5rem;
			}

			@media screen and (min-width: 1700px) {
				gap: 2rem;
			}

			.navOption {
				color: #444343;
				position: relative;
				display: flex;
				align-items: center;
				font-weight: 600;
				font-size: 0.9rem;

				@media screen and (min-width: 1400px) {
					font-size: 1rem;
				}

				@media screen and (min-width: 1700px) {
					font-size: 1.25rem;
				}

				p {
					display: flex;
					align-items: center;
					cursor: pointer;
					gap: 0.4rem;

					svg {
						height: 1rem;

						path {
							stroke: #444343;
						}
					}
				}

				.navOptionActive {
					color: #405fec;

					svg {
						transform: rotate(-180deg);

						path {
							stroke: #405fec;
						}
					}
				}

				.navDropdown {
					position: absolute;
					top: calc(var(--nav_height) - (var(--nav_height) / 2.8));
					transform: translateX(-50%);
					background: white;
					box-shadow: 0px 1px 32px 1px rgba(109, 141, 173, 0.2);
					color: rgb(37, 37, 37);
					border-bottom-left-radius: 1rem;
					border-bottom-right-radius: 1rem;
					display: grid;
					grid-template-columns: repeat(3, 1fr);
					grid-template-rows: auto;
					grid-gap: 2rem;
					width: 100vw;
					max-width: 56rem;
					// opacity: 0;
					max-height: 0;
					// pointer-events: none;
					padding: 0 2rem;
					overflow: hidden;
					transition: all .4s linear;

					@media screen and (min-width: 1000px) {
						transform: translateX(-65%);
						max-width: 55rem;
					}

					@media screen and (min-width: 1400px) {
						transform: translateX(-55%);
						max-width: 65rem;
						padding: 0 1.5rem;
						gap: 1.5rem;
					}

					@media screen and (min-width: 1700px) {
						transform: translateX(-50%);
						max-width: 75rem;
						grid-gap: 2rem;
						padding:  0 2rem;
					}

				}

				.businessDropdown {
					transform: translateX(-55%);

					@media screen and (min-width: 1400px) {
						transform: translateX(-60%);
					}

					@media screen and (min-width: 1700px) {
						transform: translateX(-55%);
					}
				}

				.navDropdownActive {
					max-height: 25rem;
					opacity: 1;
					padding: 2rem;

					@media screen and (min-width: 1400px) {
						padding: 1.5rem;
					}

					@media screen and (min-width: 1400px) {
						padding: 2rem;
					}
				}
			}

			.navOption:hover{
				color: #405fec;

				p svg {

					path {
						stroke: #405fec;
					}
				}

			}
		}
	}

	@media screen and (max-width: 550px) {
		.flags_and_button_container {
			width: max-content;
			display: flex;
			gap: 0.7rem;
			align-items: center;
			.flags {
				display: none !important;
			}
		}
	}

	@media screen and (min-width: 550px) {
		// height: 5rem;
		.flags_and_button_container {
			width: max-content;

			display: flex;
			align-items: center;
			gap: 2rem;
		}
	}

	// @media screen and (min-width: 730px) {
	//   height: 4rem;
	// }

	// @media screen and (min-width: 1200px) {
	//   height: 6rem;
	// }

	// @media screen and (min-width: 1400px) {
	//   height: 5rem;
	// }

	@media screen and (min-width: 1700px) {
		padding: 1.12rem 5.62rem;
	}
}

.logoContainer {
	display: flex;
	align-items: center;
	cursor: pointer;

	a {
		display: flex;
	}
}

.logo {
	height: 2rem;

	@media screen and (min-width: 550px) {
		height: 1.5rem;
	}

	@media screen and (min-width: 730px) {
		height: 1.7rem;
	}

	@media screen and (min-width: 1400px) {
		height: 1.8rem;
	}

	@media screen and (min-width: 1700px) {
		height: 2.2rem;
	}
}

.gatewayLogo{
	height: 2.2rem;

	@media screen and (min-width: 550px) {
		height: 2.5rem;
	}

	@media screen and (min-width: 730px) {
		height: 2.3rem;
	}

	@media screen and (min-width: 1400px) {
		height: 3.2rem;
	}

	@media screen and (min-width: 1700px) {
		height: 3.5rem;
	}
}

// .logo path{
//     fill: var(--logo_color) !important;
// }

.toggleContainer {
	display: flex;
	align-items: center;
	justify-content: center;
}

.toggleIcon {
	width: 1.5rem;
	height: 1.5rem;
	cursor: pointer;

	@media screen and (min-width: 550px) {
		height: 3rem;
		width: 1.5rem;
	}

	@media screen and (min-width: 1200px) {
		height: 3rem;
		width: 2rem;
	}
}

.downloadButtonContainer {
	width: 6.2rem;
	width: auto;

	@media screen and (min-width: 730px) {
		width: 7.2rem;
		width: auto;
	}

	@media screen and (min-width: 1400px) {
		width: 8.8rem;
		width: auto;
	}

	@media screen and (min-width: 1700px) {
		width: 10.6rem;
		width: auto;
	}
}

.ham {
	height: 2rem;
}

.sideMenuContainer {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: flex-end;
	background: rgba(0, 0, 0, 0.197);
	z-index: 6;
	
	hr {
		border-top: 1px solid #f2f2f2;
	}

	.sideMenuWrapper {
		overflow-x: hidden;
		overflow-y: auto;
	}
}

.sideMenu {
	width: auto;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background: white;
	transition: all .4s  ease-out ;
	padding: 5rem 1.6rem;
	position: relative;	
}

.sideMenuActive {
	width: 80vw;
	max-width: 550px;
}

.menuCloseIcon {
	position: absolute;
	top: 1.5rem;
	right: 1rem;
	height: 2.5rem;
}

.menu {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 2rem;

	.menuHeading {
		color: black;
		font-weight: 500;
		font-size: 1.25rem;
		display: flex;
		align-items: center;
		gap: 0.5rem;

		.arrowDropdown {
			height: 1.5rem;
		}
	}

	.enterpriseActive {
		color: var(--zunu_secondary_color);

		svg {
			transform: rotate(-180deg);

			path {
				stroke: var(--zunu_secondary_color);
			}
		}
	}

	.menuProductsContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 2.6rem;
	}
}

.menuProduct {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 0.4rem;

	@media screen and (min-width: 1400px) {
		padding: 1rem;
		border-radius: 1rem;
	}
}

@media screen and (min-width: 1400px) {
	.menuProduct:hover {
		background: #f2f3ff;
	}
}

.productLogo {
	display: flex;
	align-items: center;
	gap: 0.6rem;

	svg {
		height: 2rem !important;

		@media screen and (min-width: 1700px) {
			height: 3.5rem;
		}
	}

	p {
		font-weight: 600;
		color: black;
		font-size: 1.3rem;

		@media screen and (min-width: 1700px) {
			font-size: 1.5rem;
		}
	}
	.zunuBusinessIcons{
		max-width: 100%;
		height: 2.1rem !important;

		@media screen and (min-width: 1400px) {
			height: 2.7rem !important;
		}
	}
}

.productDescription {
	color: #1c1b1f;
	font-size: 0.9rem;
	font-weight: 400;

	@media screen and (min-width: 1200px) {
		font-weight: 400;
	}
	@media screen and (min-width: 1700px) {
		font-size: 1.1rem;
	}
}
.menuBusinessContainer{
	display: flex;
	flex-direction: column;
	gap: 2.6rem;
	max-height: 0rem;
	overflow: hidden;
	transition: all .4s linear;
	
}
.businessActive{
	max-height: 100rem;
	overflow: hidden;
}